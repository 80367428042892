<template>
  <b-container fluid class="tm-color-body" style="margin-top: 16px">
        <b-row align-h="around">
            <b-col md="6">
                <div class="shadow p-1 my-3">
                    <h3 class="m-2 tm-bottom-seperator">Sales Lines</h3>
                    <div class="mx-5">
                        <h5>Metro Detroit</h5>
                        <p class="mx-3">(248) 965-9019 ext. 1</p>
                        <h5>Grand Rapids</h5>
                        <p class="mx-3">(616) 226-3313 ext. 1</p>
                        <h5>Outside Michigan</h5>
                        <p class="mx-3">(866) 950-0006 ext. 1</p>
                    </div>
                </div>
            </b-col>
            <b-col md="6">
                <div class="shadow p-1 my-3">
                    <h3 class="m-2 tm-bottom-seperator">Contact Directory</h3>
                    <div class="mx-5">
                        <h5>Ferndale Main Office</h5>
                        <p class="mx-3">(248) 965-9019</p>
                        <h5>Dispatch/Office </h5>
                        <p class="mx-3">(866) 950-0006 ext. 0 (Daily 5:30am-12am)</p>
                        <h5>Grand Rapids Office</h5>
                        <p class="mx-3">(616) 226-3313</p>
                        <h5>After Hours Officer</h5>
                        <p class="mx-3">(248) 965-9019 ext. 3</p>
                        <h5>Finance AP/AR</h5>
                        <p class="mx-3">(866) 950-0006 ext. 4</p>
                    </div>
                </div>
            </b-col>
        </b-row>
        <div style="margin-top:10rem; text-align:center">
            <div class="mx-2">
                <div>
                    MI licensed Security Guard Agency:
                    <span class="mx-2"><br><b>3801207330</b></span>
                </div>
                <div>
                    MI licensed Private Investigator Agency:
                    <span class="mx-2"><br><b>3701207363</b></span>
                </div>
            </div>
        </div>
  </b-container>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    msg: String
  },
  data() {
    return {message: "About!"}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
