<template>
  <div class="mx-3 tm-color-body" style="margin-top: 32px">
    <div class="m-3">
        <h3 class="tm-color-heading">Private Investigation</h3>
        <div class="my-3">
            <p class="tm-color-body">
                  We provide a wide array of private investigation work, with a specialization in surveillance and 
                  cyber-surveillance and can tailor any private investigation services to any needs you may have. 
                  Our investigators are trained to conduct covert surveillance, gather intelligence, and implement 
                  GPS tracking technology. Our investigators are thorough and will provide you with any and all information 
                  you need to acquire on a particular individual.    
            </p>
        </div>
        <svg width="100%" height="auto" viewBox="0 0 1175 567" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <clipPath id="myMask">
                <circle ref="lightMask" cx="500" cy="200" r="250" />
            </clipPath>
            <image clip-path="url(#myMask)" xlink:href="../assets/brickscene.svg" />
        </svg>
        <b-container class="my-2" fluid>
            <b-row align-h="around">
                <b-col md="6" class="mb-3" style="text-align:left">
                    <h3 class="tm-color-heading my-3">Surveillance</h3>
                    <p>We can provide multiple different types of surveillance including:</p>

                    <p><b>Residence Surveillance:</b> A private investigator will station themselves in a covert position to monitor a subject's home for activity and can confirm a subject is in the residence through several different techniques.</p>

                    <p><b>Mobile Surveillance:</b> A private investigator can follow a subject as they are transported to different locations.</p>

                    <p><b>GPS Tracking:</b> We have GPS tracking devices we can utilize on subject vehicles to log the whereabouts of that vehicle over a designated timeframe.</p>
                </b-col>
                <b-col md="6" class="my-3" style="text-align:left">
                    <b-img thumbnail center fluid style="max-width:600px; width:100%" src="../assets/camera.jpg"></b-img>
                    <!-- <info-card v-bind="this.cyberSurveillance" style="margin:auto"></info-card> -->
                </b-col>
            </b-row>
        </b-container>
    </div>
  </div>  
</template>

<script>
import anime from 'animejs/lib/anime.es.js'
// import InfoCard from './InfoCard.vue'

export default {
    name: 'Investigation',
    components: { 
        // InfoCard 
    },
    props: {
        msg: String
    },
    data() {
        return {
            surveillance: {
                title: "Surveillance",
                hint: "Read More",
                body:   `
                        We can provide multiple different types of surveillance including:

                        <p>Residence Surveillance: A private investigator will station themselves in a covert position to monitor a subject's home for activity and can confirm a subject is in the residence through several different techniques.</p>

                        <p>Mobile Surveillance: A private investigator can follow a subject as they are transported to different locations.</p>

                        <p>GPS Tracking: We have GPS tracking devices we can utilize on subject vehicles to log the whereabouts of that vehicle over a designated timeframe.</p>
                        `
            },
            cyberSurveillance: {
                title: "Cyber-Surveillance",
                hint: "Read More",
                body:   `
                        We can provide any number of cyber-surveillance techniques to gain the information you need including:

                        <p>Social Networking Monitoring: One of our specialists will monitor a subject's social network feeds including Facebook, Twitter, Instagram, Youtube, etc. to get the information you require.</p>

                        <p>Background Checks: Our team can perform in-depth background checks to gain history on a subject as well as their email addresses and phone numbers.</p>

                        <p>Metadata inspection: Our technology specialists can inspect metadata obtained from a number of sources such as emails and photos to ascertain information such as location, IP addresses, etc.</p>
                        `
            },
        }
    }, 
    mounted: function() {
        this.animateLight();
    },
    methods: {
        animateLight() {
            let target = this.$refs.lightMask
            let timeline = anime.timeline({loop: false})
            timeline.add({
                // targets: this.$refs.cloud,
                targets: target,
                duration: 10000,
                cx: function() {return anime.random(300, 1000) },
                cy: function() { return anime.random(50, 400)},
                easing: 'easeInOutSine',
                delay: 0,
                direction: 'normal',
                loop: false,
            });
            timeline.complete = () => {
                this.animateLight();
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
