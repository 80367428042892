<template>
  <div class="mx-3 tm-color-body" style="margin-top: 16px; ">
    <div class="m-3">
        <h3 class="tm-color-heading">Security</h3>
        <div class="my-3">
            <p class="tm-color-body">
                We provide services to multi-national corporations as well as private clients for on-site armed and unarmed security, 
                escorting, or any customized security detail. We can offer services for a single event, or provide up to round-the-clock security for 
                locations 7-days a week, 365-days a year, including holidays. Our services are fully customized to your security needs and can be flexible to accommodate any situation.
                Below are some of our services. Our work doesn't stop there however! Our security details are fully customizable to any needs you may have. 
                Feel free to request more information or a quote based around the exact specifications of your security needs.
            </p>
        </div>
    </div>
    <svg class="pt-5" width="100%" height="auto" viewBox="0 0 1735 630" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <polygon id="path-sbe9z94cxw-1" points="69.1720237 9 112 9 105.982456 20 63 20"></polygon>
                <polygon id="path-sbe9z94cxw-3" points="169.172024 39 212 39 205.982456 50 163 50"></polygon>
                <polygon id="path-sbe9z94cxw-5" points="519.172024 29 562 29 555.982456 40 513 40"></polygon>
            </defs>
            <g id="Security-Car" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect id="Lot" fill="#D8D8D8" x="0" y="367" width="1735" height="256"></rect>
                <rect id="Sky" fill="#9DFEFF" x="0" y="0" width="1735" height="321"></rect>
                <rect id="Rectangle" fill="#8AD298" x="0" y="321" width="1735" height="46"></rect>
                <polygon id="Rectangle" fill="#8AD298" points="200.115943 623 398.133532 364 559 364 379.557971 623"></polygon>
                <polygon id="Rectangle" fill="#8AD298" points="1158 519 1239 364 1359.88442 364 1282.45785 519"></polygon>
                
                <image class="cloud" xlink:href="../assets/cloud-1.svg" y=-80 x=60 height="150" width="150" />
                <image class="cloud" xlink:href="../assets/Cloud-3.svg" y=0 x=600 height="150" width="150" />
                <image class="cloud" xlink:href="../assets/Cloud-2.svg" y=-10 x=1300 height="150" width="150" />
                <image class="cloud" xlink:href="../assets/Cloud-4.svg" y=-50 x=1500 height="150" width="150" />

                <g id="Tree-3" transform="translate(83.000000, 234.500000)">
                    <rect id="trunk" fill="#CB906F" x="28" y="57.5" width="15" height="63"></rect>
                    <circle id="leaf" fill="#77BA84" cx="22" cy="69" r="22"></circle>
                    <circle id="leaf" fill="#77BA84" cx="48" cy="66" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="25" cy="40" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="44" cy="40" r="15"></circle>
                    <circle id="leaf" fill="#77BA84" cx="33" cy="15" r="15"></circle>
                </g>
                <g id="Tree-3" transform="translate(397.000000, 324.500000)">
                    <rect id="trunk" fill="#CB906F" x="28" y="57.5" width="15" height="63"></rect>
                    <circle id="leaf" fill="#77BA84" cx="22" cy="69" r="22"></circle>
                    <circle id="leaf" fill="#77BA84" cx="48" cy="66" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="25" cy="40" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="44" cy="40" r="15"></circle>
                    <circle id="leaf" fill="#77BA84" cx="33" cy="15" r="15"></circle>
                </g>
                <g id="Tree-1" transform="translate(1499.500000, 299.750000) scale(-1, 1) translate(-1499.500000, -299.750000) translate(1466.000000, 244.500000)">
                    <rect id="trunk" fill="#CB906F" x="28" y="47.5" width="15" height="63"></rect>
                    <circle id="leaf" fill="#77BA84" cx="22" cy="69" r="22"></circle>
                    <circle id="leaf" fill="#77BA84" cx="48" cy="66" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="25" cy="40" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="44" cy="40" r="15"></circle>
                    <circle id="leaf" fill="#77BA84" cx="33" cy="15" r="15"></circle>
                </g>
                <g id="Tree-1" transform="translate(370.500000, 474.750000) scale(-1, 1) translate(-370.500000, -474.750000) translate(337.000000, 419.500000)">
                    <rect id="trunk" fill="#CB906F" x="28" y="47.5" width="15" height="63"></rect>
                    <circle id="leaf" fill="#77BA84" cx="22" cy="69" r="22"></circle>
                    <circle id="leaf" fill="#77BA84" cx="48" cy="66" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="25" cy="40" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="44" cy="40" r="15"></circle>
                    <circle id="leaf" fill="#77BA84" cx="33" cy="15" r="15"></circle>
                </g>
                <g id="Tree-2" transform="translate(1630.000000, 217.500000)">
                    <rect id="trunk" fill="#CB906F" x="39" y="76.5" width="15" height="63"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(52.500000, 71.500000) rotate(24.000000) translate(-52.500000, -71.500000) " x="49" y="49.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(62.500000, 93.500000) rotate(65.000000) translate(-62.500000, -93.500000) " x="59" y="71.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(44.619993, 50.118032) rotate(-4.000000) translate(-44.619993, -50.118032) " x="42.6199933" y="22.6180324" width="4" height="55"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(36.041371, 65.883819) rotate(-30.000000) translate(-36.041371, -65.883819) " x="32.5413706" y="43.8838195" width="7" height="44"></rect>
                    <circle id="leaf" fill="#77BA84" cx="22" cy="88" r="22"></circle>
                    <circle id="leaf" fill="#77BA84" cx="78" cy="85" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="25" cy="49" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="62" cy="53" r="15"></circle>
                    <circle id="leaf" fill="#77BA84" cx="44" cy="15" r="15"></circle>
                </g>
                <g id="Tree-2" transform="translate(262.000000, 215.500000)">
                    <rect id="trunk" fill="#CB906F" x="39" y="76.5" width="15" height="63"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(52.500000, 71.500000) rotate(24.000000) translate(-52.500000, -71.500000) " x="49" y="49.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(62.500000, 93.500000) rotate(65.000000) translate(-62.500000, -93.500000) " x="59" y="71.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(44.619993, 50.118032) rotate(-4.000000) translate(-44.619993, -50.118032) " x="42.6199933" y="22.6180324" width="4" height="55"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(36.041371, 65.883819) rotate(-30.000000) translate(-36.041371, -65.883819) " x="32.5413706" y="43.8838195" width="7" height="44"></rect>
                    <circle id="leaf" fill="#77BA84" cx="22" cy="88" r="22"></circle>
                    <circle id="leaf" fill="#77BA84" cx="78" cy="85" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="25" cy="49" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="62" cy="53" r="15"></circle>
                    <circle id="leaf" fill="#77BA84" cx="44" cy="15" r="15"></circle>
                </g>
                <g id="Tree-2" transform="translate(246.000000, 484.500000)">
                    <rect id="trunk" fill="#CB906F" x="39" y="76.5" width="15" height="63"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(52.500000, 71.500000) rotate(24.000000) translate(-52.500000, -71.500000) " x="49" y="49.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(62.500000, 93.500000) rotate(65.000000) translate(-62.500000, -93.500000) " x="59" y="71.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(44.619993, 50.118032) rotate(-4.000000) translate(-44.619993, -50.118032) " x="42.6199933" y="22.6180324" width="4" height="55"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(36.041371, 65.883819) rotate(-30.000000) translate(-36.041371, -65.883819) " x="32.5413706" y="43.8838195" width="7" height="44"></rect>
                    <circle id="leaf" fill="#77BA84" cx="22" cy="88" r="22"></circle>
                    <circle id="leaf" fill="#77BA84" cx="78" cy="85" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="25" cy="49" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="62" cy="53" r="15"></circle>
                    <circle id="leaf" fill="#77BA84" cx="44" cy="15" r="15"></circle>
                </g>
                <g id="Tree-3" transform="translate(494.500000, 285.250000) scale(-1, 1) translate(-494.500000, -285.250000) translate(446.000000, 215.500000)">
                    <rect id="trunk" fill="#CB906F" x="39" y="76.5" width="15" height="63"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(52.500000, 71.500000) rotate(24.000000) translate(-52.500000, -71.500000) " x="49" y="49.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(62.500000, 93.500000) rotate(65.000000) translate(-62.500000, -93.500000) " x="59" y="71.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(44.619993, 50.118032) rotate(-4.000000) translate(-44.619993, -50.118032) " x="42.6199933" y="22.6180324" width="4" height="55"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(36.041371, 65.883819) rotate(-30.000000) translate(-36.041371, -65.883819) " x="32.5413706" y="43.8838195" width="7" height="44"></rect>
                    <circle id="leaf" fill="#77BA84" cx="22" cy="88" r="22"></circle>
                    <circle id="leaf" fill="#77BA84" cx="78" cy="85" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="25" cy="49" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="62" cy="53" r="15"></circle>
                    <circle id="leaf" fill="#77BA84" cx="44" cy="15" r="15"></circle>
                </g>
                <g id="Tree-3" transform="translate(1290.500000, 287.250000) scale(-1, 1) translate(-1290.500000, -287.250000) translate(1242.000000, 217.500000)">
                    <rect id="trunk" fill="#CB906F" x="39" y="76.5" width="15" height="63"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(52.500000, 71.500000) rotate(24.000000) translate(-52.500000, -71.500000) " x="49" y="49.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(62.500000, 93.500000) rotate(65.000000) translate(-62.500000, -93.500000) " x="59" y="71.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(44.619993, 50.118032) rotate(-4.000000) translate(-44.619993, -50.118032) " x="42.6199933" y="22.6180324" width="4" height="55"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(36.041371, 65.883819) rotate(-30.000000) translate(-36.041371, -65.883819) " x="32.5413706" y="43.8838195" width="7" height="44"></rect>
                    <circle id="leaf" fill="#77BA84" cx="22" cy="88" r="22"></circle>
                    <circle id="leaf" fill="#77BA84" cx="78" cy="85" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="25" cy="49" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="62" cy="53" r="15"></circle>
                    <circle id="leaf" fill="#77BA84" cx="44" cy="15" r="15"></circle>
                </g>
                <g id="Building" transform="translate(516.000000, 233.000000)">
                    <rect id="Front" fill="#E5E5E5" x="266" y="40" width="151" height="157"></rect>
                    <polygon id="Rectangle" fill="#D8D8D8" points="435.402344 0 688 0 652.508772 65 399 65"></polygon>
                    <polygon id="Rectangle" fill="#D8D8D8" points="36.4023437 0 289 0 253.508772 65 0 65"></polygon>
                    <polygon id="Rectangle" fill="#D8D8D8" points="288.678674 1.42108547e-13 436.402344 1.42108547e-13 414.363805 40 266.228375 40"></polygon>
                    <rect id="Front" fill="#E5E5E5" x="0" y="65" width="253" height="157"></rect>
                    <polygon id="Front" fill="#B8B8B8" points="252 65 266.228375 40 266.228375 197 252 222"></polygon>
                    <polygon id="Front" fill="#B8B8B8" points="652 65 688 -4.17443857e-14 688 157 652 222"></polygon>
                    <rect id="Front" fill="#E5E5E5" x="399" y="65" width="253" height="157"></rect>
                    <rect id="Window" fill="#ACD9D4" x="12" y="154" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="63" y="154" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="114" y="154" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="165" y="154" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="216" y="154" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="12" y="84" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="63" y="84" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="114" y="84" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="165" y="84" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="216" y="84" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="295" y="54" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="346" y="54" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="412" y="84" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="463" y="84" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="514" y="84" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="565" y="84" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="616" y="84" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="412" y="154" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="463" y="154" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="514" y="154" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="565" y="154" width="26" height="47"></rect>
                    <rect id="Window" fill="#ACD9D4" x="616" y="154" width="26" height="47"></rect>
                    <rect id="Rectangle" fill="#D8D8D8" x="299" y="131" width="68" height="66"></rect>
                    <mask id="mask-sbe9z94cxw-2" fill="white">
                        <use xlink:href="#path-sbe9z94cxw-1"></use>
                    </mask>
                    <use id="Rectangle" fill="#797979" xlink:href="#path-sbe9z94cxw-1"></use>
                    <mask id="mask-sbe9z94cxw-4" fill="white">
                        <use xlink:href="#path-sbe9z94cxw-3"></use>
                    </mask>
                    <use id="Rectangle" fill="#797979" xlink:href="#path-sbe9z94cxw-3"></use>
                    <mask id="mask-sbe9z94cxw-6" fill="white">
                        <use xlink:href="#path-sbe9z94cxw-5"></use>
                    </mask>
                    <use id="Rectangle" fill="#797979" xlink:href="#path-sbe9z94cxw-5"></use>
                </g>
                <g id="Tree-2" transform="translate(1193.000000, 343.500000)">
                    <rect id="trunk" fill="#CB906F" x="39" y="76.5" width="15" height="63"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(52.500000, 71.500000) rotate(24.000000) translate(-52.500000, -71.500000) " x="49" y="49.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(62.500000, 93.500000) rotate(65.000000) translate(-62.500000, -93.500000) " x="59" y="71.5" width="7" height="44"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(44.619993, 50.118032) rotate(-4.000000) translate(-44.619993, -50.118032) " x="42.6199933" y="22.6180324" width="4" height="55"></rect>
                    <rect id="trunk" fill="#CB906F" transform="translate(36.041371, 65.883819) rotate(-30.000000) translate(-36.041371, -65.883819) " x="32.5413706" y="43.8838195" width="7" height="44"></rect>
                    <circle id="leaf" fill="#77BA84" cx="22" cy="88" r="22"></circle>
                    <circle id="leaf" fill="#77BA84" cx="78" cy="85" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="25" cy="49" r="19"></circle>
                    <circle id="leaf" fill="#77BA84" cx="62" cy="53" r="15"></circle>
                    <circle id="leaf" fill="#77BA84" cx="44" cy="15" r="15"></circle>
                </g>
                <g id="Car" transform="translate(573.000000, 461.000000)">
                    <polygon id="Top" fill-opacity="0.25" fill="#757575" points="28.3901114 26 152.070175 26 138.070175 59 10 59"></polygon>
                    <polygon id="Top" fill="#C9C9C9" points="18.3901114 16 142.070175 16 128.070175 49 0 49"></polygon>
                    <polygon id="Rectangle" fill="#C9C9C9" points="64.5519848 10 141.772727 10 134.921451 29 54 29"></polygon>
                    <polygon id="Rectangle" fill="#ACD9D4" points="95.7727273 0 109.128788 10 100 29 85.4848485 19"></polygon>
                    <polygon id="Rectangle" fill="#ACD9D4" points="86 21.5 96.2584439 29.25 66.0350877 29 66.0350877 21.5"></polygon>
                    <polygon id="Rectangle" fill="#BCB8B8" points="27.1934185 0 95.7727273 0 85.4848485 19.5 16.3327409 19.5"></polygon>
                    <polygon id="Rectangle" fill="#A4A4A4" points="141.772727 9.5 141.772727 16 128.070175 49 128.070175 40.167157"></polygon>
                    <circle id="Oval" fill="#666666" cx="32" cy="47" r="11"></circle>
                    <circle id="Oval" fill="#666666" cx="102" cy="47" r="11"></circle>
                </g>
                <g id="Car" transform="translate(973.000000, 461.000000)">
                    <polygon id="Top" fill-opacity="0.25" fill="#757575" points="28.3901114 26 152.070175 26 138.070175 59 10 59"></polygon>
                    <polygon id="Top" fill="#7EB5D2" points="18.3901114 16 142.070175 16 128.070175 49 0 49"></polygon>
                    <polygon id="Rectangle" fill="#7EB5D2" points="64.5519848 10 141.772727 10 134.921451 29 54 29"></polygon>
                    <polygon id="Rectangle" fill="#ACD9D4" points="95.7727273 0 109.128788 10 100 29 85.4848485 19"></polygon>
                    <polygon id="Rectangle" fill="#ACD9D4" points="86 21.5 96.2584439 29.25 66.0350877 29 66.0350877 21.5"></polygon>
                    <polygon id="Rectangle" fill="#709FB8" points="27.1934185 0 95.7727273 0 85.4848485 19.5 16.3327409 19.5"></polygon>
                    <polygon id="Rectangle" fill="#A4A4A4" points="141.772727 9.5 141.772727 16 128.070175 49 128.070175 40.167157"></polygon>
                    <circle id="Oval" fill="#666666" cx="32" cy="47" r="11"></circle>
                    <circle id="Oval" fill="#666666" cx="102" cy="47" r="11"></circle>
                </g>
                <g id="Car" transform="translate(538.000000, 511.000000)">
                    <polygon id="Top" fill-opacity="0.25" fill="#757575" points="28.3901114 26 152.070175 26 138.070175 59 10 59"></polygon>
                    <polygon id="Top" fill="#C9C9C9" points="18.3901114 16 142.070175 16 128.070175 49 0 49"></polygon>
                    <polygon id="Rectangle" fill="#C9C9C9" points="64.5519848 10 141.772727 10 134.921451 29 54 29"></polygon>
                    <polygon id="Rectangle" fill="#ACD9D4" points="95.7727273 0 109.128788 10 100 29 85.4848485 19"></polygon>
                    <polygon id="Rectangle" fill="#ACD9D4" points="86 21.5 96.2584439 29.25 66.0350877 29 66.0350877 21.5"></polygon>
                    <polygon id="Rectangle" fill="#BCB8B8" points="27.1934185 0 95.7727273 0 85.4848485 19.5 16.3327409 19.5"></polygon>
                    <polygon id="Rectangle" fill="#A4A4A4" points="141.772727 9.5 141.772727 16 128.070175 49 128.070175 40.167157"></polygon>
                    <circle id="Oval" fill="#666666" cx="32" cy="47" r="11"></circle>
                    <circle id="Oval" fill="#666666" cx="102" cy="47" r="11"></circle>
                </g>
                <g id="Car" transform="translate(498.000000, 561.000000)">
                    <polygon id="Top" fill-opacity="0.25" fill="#757575" points="28.3901114 26 152.070175 26 138.070175 59 10 59"></polygon>
                    <polygon id="Top" fill="#C9C9C9" points="18.3901114 16 142.070175 16 128.070175 49 0 49"></polygon>
                    <polygon id="Rectangle" fill="#C9C9C9" points="64.5519848 10 141.772727 10 134.921451 29 54 29"></polygon>
                    <polygon id="Rectangle" fill="#ACD9D4" points="95.7727273 0 109.128788 10 100 29 85.4848485 19"></polygon>
                    <polygon id="Rectangle" fill="#ACD9D4" points="86 21.5 96.2584439 29.25 66.0350877 29 66.0350877 21.5"></polygon>
                    <polygon id="Rectangle" fill="#BCB8B8" points="27.1934185 0 95.7727273 0 85.4848485 19.5 16.3327409 19.5"></polygon>
                    <polygon id="Rectangle" fill="#A4A4A4" points="141.772727 9.5 141.772727 16 128.070175 49 128.070175 40.167157"></polygon>
                    <circle id="Oval" fill="#666666" cx="32" cy="47" r="11"></circle>
                    <circle id="Oval" fill="#666666" cx="102" cy="47" r="11"></circle>
                </g>
            </g>
    </svg>
    <div style="text-align:center" class="mb-3">
        <span style="text-align:center">Pictured: A site under Proof's protection</span>        
    </div>
    <b-container fluid>
        <b-row align-h="around">
                <b-col md="4" class="my-3" style="text-align:center">
                    <info-card v-bind="this.services" style="margin:auto"></info-card>
                </b-col>
                <b-col md="4" class="my-3" style="text-align:center">
                    <info-card v-bind="this.ourMission" style="margin:auto"></info-card>
                </b-col>
                <b-col md="4" class="my-3" style="text-align:center">
                    <info-card v-bind="this.privateParties" style="margin:auto"></info-card>
                </b-col>
            </b-row>
    </b-container>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'
import InfoCard from './InfoCard.vue'

export default {
    name: 'Security',
    components: {
        InfoCard
    },
    props: {
        msg: String
    },
    data() {
        return {    
            message: "Security!", 
            services: {
                title: "Event Security",
                hint: "Read More",
                body:   `We can provide plain clothed or uniformed event security personnel. Our team will keep a low profile, but will remain constantly alert should they be needed to discretely and professionally handle any incidents that arise, ensuring the safety of both your attendees and staff.
                        For large scale events we are able to work with you to develop a crowd control strategy and traffic management plan to ensure that not only is your event safe, but that the attendees enjoy the overall experience.
                        `
            },
            ourMission: {
                title: "Concierge Services",
                hint: "Read More",
                body:   `Concierge, or hospitality require exceptional, handpicked staff with outstanding communication skills, immaculate presentation, attention to detail, incomparable customer service skills and discipline. They must be able to manage daily tasks dutifully, have confidence in engaging members of the public and have the ability to coordinate emergency situations as they arise. All concierge staff selected by PROOF Security will not only possess
                         these characteristics, but will approach their tasks from a security-
                         minded perspective.`
            },
            privateParties: {
                title: "Private Parties",
                hint: "Read More",
                body: `Do you need crowd control or a doorman for your next party? A simple private party can be spoiled by a few gate crashers, so protect your guests and event by employing the services of a professional security guard. Whether it’s for an 18th or 21st birthday party, special occasion or even a corporate event, Proof Security can provide suitably dressed, professional security personnel to blend into your event and ensure
                       complete control, as well as uniformed guards to act as a visible
                       deterrent.`
            }
        }
    },
    mounted: function() {
        
        let targets = document.querySelectorAll('.cloud')
        console.log(`targets: ${targets.length}`)
        targets.forEach(element => {
            var t1 = anime.timeline({loop: false, delay: 0});
            let location = element.getAttribute('x');
            console.log(`Distance: ${1800 - location}`)
            let distance = 1800 - location;
            t1.add({
                // targets: this.$refs.cloud,
                targets: [element],
                duration: 90 * distance,
                x: 1800,
                easing: 'linear',
                delay: 0,
                direction: 'normal',
                loop: false,
            });
            t1.finished.then(() => {
                anime({
                    // targets: this.$refs.cloud,
                    targets: [element],
                    duration: 95000,
                    x: [-150, 1800],
                    easing: 'linear',
                    delay: 0,
                    direction: 'normal',
                    loop: true,
                    
                });
            });
            
        });

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
