<template>
  <div class="mx-3 tm-color-body" style="margin-top: 32px">
        <div>
            <b-img thumbnail class="my-4 shadow" width="350px" height="auto" src="../assets/zdickerson.png" fluid></b-img>

            <!-- <b-avatar class="my-3" rounded size="350px" src="../assets/zdickerson.png"></b-avatar> -->
            <h4 class="tm-color-heading">Zachary Dickerson: Founder &amp; CEO</h4>
            <p>
                As a college student, Zack Dickerson paid his bills working as a security guard. When an
                employer failed to pay him, he vowed to become the boss he hadn't had. He left college
                behind, working three full-time jobs in security and investigations to pay his bills, gain licenses,
                and cover insurance costs. With a small team, he traveled to emergency sites, where hurricanes
                or fires had left devastation and despair, learning on the ground and in real time how to solve
                problems, even when there was no running water or electricity. He reached out to new clients,
                emphasizing their happiness. 
            </p>
            <p>
                In 2016, he launched Proof Technologies. He had a plan: To create a high-performing
                investigations and security firm that would solve clients' problems with professionalism and
                cost-effectiveness, while treating employees with respect. At age 30, Dickerson and his
                management team have built a high-powered firm with over 100 Michigan employees and a
                national network that can provide security almost anywhere.
            </p>
            <b-img thumbnail class="my-4 shadow" width="350px" height="auto" src="../assets/hhashim.png" fluid></b-img>
            <h4 class="tm-color-heading">Hayat Ahmed Hashim: President</h4>
            <p>
                Hayat's journey to Proof Technologies has been an epic one. Born in Sri Lanka, at 13
                and encouraged by their parents, she and her 16-year-old brother moved on their own to
                Canada, in pursuit of higher education. They lived in an apartment in a Quebec suburb,
                mastering English and French and the ways of a different world on their own.
            </p>
            <p>
                That resourcefulness and determination took Hayat a long way: To McGill University, and,
                eventually, a medical degree. But while she was working weekends at a security firm to meet
                the steep costs of her education, her career took a turn: She teamed up with a young security
                firm owner, Zack Dickerson, she'd hired to work at a hurricane scene. He recognized her keen
                people sense, steadiness at the helm, and ambition. She appreciated his vision, drive and
                determination. Together, they teamed up to take Proof Technologies to the next level by building a
                national network that can provide security almost anywhere.
            </p>
            <h4 class="tm-color-heading mt-5">KEVIN CANZE: General Manager</h4>
            <p>
                Kevin Canze is an art historian and technician, a lawyer, and now the general manager of Proof
                Technologies. Raised on a farm and trained in art history at the University of Michigan, Canze
                worked as an art museum technician and manager for two decades before changing careers
                and earning a law degree from Wayne State University Law School. For ten years, he worked in
                parking management, supervising up to 80 employees. It was in Ferndale, where he was
                supervising the city's parking system, that Canze met Zack Dickerson – an unusual customer,
                energetic, full of ideas, demanding yet reasonable. Two years ago, Dickerson convinced Canze
                to join Proof Technologies, bringing deep experience with people and organization to the team.
            </p>
        </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
    msg: String
  },
  data() {
    return {message: "About!"}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
